export const service_type = [
  '', 
  'đi lên sân bay', 
  'đón tại sân bay', 
  'nghỉ dưỡng', 
  'du lịch', 
  'đường dài', 
  'thuê giờ'
];
export const service_type_en = [
  '',
  'Go to the airport',
  'Pickup from the airport',
  'Go to resort',
  'Travel',
  'Long trip',
  'Rent for hour'
]

export const car_type_en = [
  '',
  '5 seats',
  '7 seats',
  '16 seats',
  '29 seats',
  '35 seats',
  '45 seats'
]

export const payment_method = ['Tiền mặt', 'Chuyển khoản'];
export const payment_method_en = ['Cash', 'Bank Transfer'];

export const payment_status = ['Chưa thanh toán', 'Đã thanh toán'];
export const payment_status_en = ['Unpaid', 'Paid'];

export const payment_type = ['', 'Trả trước 100%', '(Trả sau 100%)', '(Trả nhiều lần)'];
export const payment_type_en = ['', '(Prepayment 100%)', '(Later payment 100%)', '(Multi payment)']

export const ServiceList = [
  {
    title: 'VỀ CHUYẾN ĐI XE 5, 7, 16 chỗ',
    subtitle: [
      'Các chuyến đón khách từ 3h tới 7h sáng, hệ thống sẽ có thông tin xe muộn nhất là 23h ngày hôm trước.',
      "Các khoảng thời gian đón khác trong ngày, hệ thống sẽ có thông tin xe 15 - 30' trước khi đón."
    ]
  },
  {
    title: 'VỀ CHUYẾN ĐI XE 29, 35, 45 chỗ',
    subtitle: [
      'Hệ thống sẽ có thông tin xe muộn nhất là 22h trước khi đón khách 01 ngày'
    ]
  },
  {
    title: 'VỀ CHUYẾN ĐÓN TẠI SÂN BAY',
    subtitle: [
      'Hệ thống sẽ dựa theo thời gian hạ cánh của máy bay và thời gian khách hàng đi lấy hành lý (nếu có) để sắp xếp xe đưa đón phù hợp nhất.',
      'Khi xuống sân bay, khách hàng mở app hoặc link kiểm tra đơn hàng để check thông tin lái xe đưa đón, đồng thời chú ý điện thoại để lái xe có thể liên hệ với khách hàng.'
    ]
  },
  {
    title: 'BẤT KHẢ KHÁNG',
    subtitle: [
      'Trong trường hợp mưa bão, đường ngập lụt hoặc các trường hợp bất khả kháng xe không thể di chuyển thì chuyến đi sẽ bị huỷ và khách hàng sẽ được hỗ trợ hoàn tiền theo quy định.'
    ]
  }
];
export const ServiceList_en = [
  {
    title: 'Note about 5, 7, 16 seats car type',
    subtitle: [
      'When pickup time from 3am to 7am, System will have car information latest in 11pm one day before.',
      "The other time, System will have car information 30 mins before pickup time."
    ]
  },
  {
    title: 'Note about 29, 35, 45 seats car type',
    subtitle: [
      'System will have car information latest in 10pm one day before'
    ]
  },
  {
    title: 'Note about pickup from the airport service',
    subtitle: [
      'System will follow flight number in the real time to arranger car suitable.',
      'When the plane landed, please open the mobile application or check the order link to get your car information. You also remember to turn on your phone to get a call from driver.'
    ]
  },
  {
    title: 'Majeure',
    subtitle: [
      'In case of rainstorm, flooded road or force majeure circumstances, the vehicle cannot move, the trip will be canceled and the customer will be supported with a refund according to regulations.'
    ]
  }
];