export const Media_source = [
  {
    src: './Image/Home/00-pippip3.jpg',
    EnglishText: "Explore What's New",
    VietnameseText: "Khám phá dịch vụ Pips"
  },
  {
    src:'/Image/Home/01-Gototheairport.jpg',
    EnglishText: "Go to the Airport",
    VietnameseText: "Đón khách lên sân bay"
  },
  {
    src: '/Image/Home/02-Pickupfromtheairport.png',
    EnglishText: "Pickup from the Airport",
    VietnameseText: "Đón khách tại sân bay"
  },
  {
    src: '/Image/Home/03-Gototheresortresort.png',
    EnglishText: "Go to the Resort",
    VietnameseText: "Đi nghỉ dưỡng"
  },
  {
    src: '/Image/Home/04-Rentforhour.jpg',
    EnglishText: "Rent for hour",
    VietnameseText: "Thuê xe theo giờ"
  },
  {
    src: '/Image/Home/05-Calltaxi.png',
    EnglishText: "Call Taxi",
    VietnameseText: "Gọi taxi "
  }
];