
import './Home.css';
import {
  HomePageWrapper,
  Text1,
  Img1,
  Img2,
  Text2
} from './HomeStyle';
import Component2Mobile from './Component2/Component2Mobile';
import Component2PC from './Component2/Component2PC';

export default function Home() {
  const language = localStorage.getItem('language');
  
  return(
    <div id='home-page' className='home-page component-layout'>
      <HomePageWrapper>
        <div className="first-component">
          <div className="flex-box">
            <Text1>
              {language === '1' ? 
                'Pips car booking service'
                :
                'Dịch vụ đặt xe Pips'
              }
              
            </Text1>
            <Img1>
              <img
                src='Image/Home/00-pippip1.jpg'
                alt=''
              />
            </Img1>
          </div>
          <div className="flex-box flex-box-reverse">
            <Img2>
              <img
                src='/Image/Home/00-pippip2.jpg'
                alt=''
              />
            </Img2>
            <Text2>
              {language === '1' ?
                'Cost optimization and throughfull customer care'
                :
                'Tối ưu chi phí và chăm sóc khách hàng chu đáo'
              }
            </Text2>
          </div>
        </div>
        <Component2PC />
        <Component2Mobile />
      </HomePageWrapper>
    </div>
  );
}