import './Footer.css';
import Copyright from '../Copyright/Copyright';

function Footer() {
  const language = localStorage.getItem('language');

  if (language === '1') return (
    <div className='footer component-layout'>
      <div className='menu-footer component-wrapper'>
        <div className="sub-content">
          <a
            href='https://info.pippip.vn/'
            target='_blank'
            rel="noreferrer"
            className='footer-title'
          >About us</a>
          <div className='text-p-big'>
            <a
              href='https://hieu.pippip.vn/'
              target='_blank'
              rel="noreferrer"
              className='text-p-big'
            >Founder, Hiếu Trần</a>
          </div>
          <div className='text-p-big'>
            <img src='/Image/zalo-icon.png' alt='' />
            <a
              className='text-p-big'
              href='tel:+84965670347'
            >&nbsp;096 567 0347</a>
          </div>
        </div>
        <div className='sub-content'>
          <div className='footer-title'>Location</div>
          <div className='text-p-big'>Hà Nội</div>
          <div className="text-p-big">Đà Nẵng</div>
          <div className='text-p-big'>Hồ Chí Minh</div>
        </div>
        <div className='sub-content'>
          <div className='footer-title'>Community</div>
          {/* <div className='text-p-big'>
            <a
              href='https://pipgo.vn/'
              target='_blank'
              rel="noreferrer"
              className='text-p-big'
            >Pipgo Travel</a>
          </div> */}
          <div className='text-p-big'>
            <a
              href='https://pips.vn/'
              target='_blank'
              rel="noreferrer"
              className='text-p-big'
            >Pips Services</a>
          </div>
          {/* <div className='text-p-big'>
            <a
              href='https://pipcar.vn/'
              target='_blank'
              rel="noreferrer"
              className='text-p-big'
            >Pipcar Community</a>
          </div> */}
        </div>
      </div>
      <Copyright />
    </div>
  );
  else return (
    <div className='footer component-layout'>
      <div className='menu-footer component-wrapper'>
        <div className="sub-content">
          <div className='footer-title'>Pips</div>
          <div className="text-p-big">Dịch vụ đặt xe sân bay,</div>
          <div className='text-p-big'>du lịch, nghỉ dưỡng trọn gói</div>
        </div>
        <div className='sub-content'>
          <div className='footer-title'>Văn phòng</div>
          <div className='text-p-big'>Hà Nội</div>
          <div className="text-p-big">Đà Nẵng</div>
          <div className='text-p-big'>Hồ Chí Minh</div>
        </div>
        <div className='sub-content'>
          <div className='footer-title'>Sản phẩm</div>
          {/* <div className='text-p-big'>
            <a
              href='https://pipgo.vn/'
              target='_blank'
              rel="noreferrer"
              className='text-p-big'
            >Du lịch Pipgo</a>
          </div> */}
          <div className='text-p-big'>
            <a
              href='https://pips.vn/'
              target='_blank'
              rel="noreferrer"
              className='text-p-big'
            >Đặt xe Pips</a>
          </div>
          {/* <div className='text-p-big'>
            <a
              href='https://pipcar.vn/'
              target='_blank'
              rel="noreferrer"
              className='text-p-big'
            >Mạng lưới xe Pipcar</a>
          </div> */}
        </div>
      </div>
      <Copyright />
    </div>
  )
}

export default Footer;