import { Form, Input, Button } from 'antd'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './CheckOrder.css';

export default function CheckOrder(props) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const language = localStorage.getItem('language');

  const handleSearchOrder = async (value) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/api/order-code/check?code=${value.orderCode}&phone=${value.phoneNumber}`);
      const order = await res.json()
      if (order.status === 1) {
        if (order.data?.comboContract?.contract_list?.length) {
          props.setOrderDetail(order.data.comboContract);
          props.setType(true);    // type = true is combo contract
          navigate(`/checkdonhang/${value.orderCode}`);
        }
        else if (order.data?.order_airport) {
          props.setOrderDetail(order.data.order_airport);
          props.setType(false);   // type = false is order airport
          navigate(`/checkdonhang/${value.orderCode}`);
        }
        else {
          setErrorMessage('Invalid Code');
          document.getElementById('error-layer').classList.remove('hide');
        }
      }
      else {
        setErrorMessage(order.message);
        document.getElementById('error-layer').classList.remove('hide');
      }
    } catch (error) {
      console.log(error);
      setErrorMessage('Dường như đã có lỗi xảy ra');
      document.getElementById('error-layer').classList.remove('hide');
    }
  }

  const ExitErrorMessage = () => {
    setErrorMessage('');
    document.getElementById('error-layer').classList.add('hide');
  }

  return (
    <div className="check-order component-layout">
      <div className="check-order-wrapper component-wrapper">
        <div className="main-content">
          <div className="order-info">
            {language === '1' ?
              <>
                <h1>Check Order</h1>
                <p>Use your ORDER CODE and</p>
                <p>PHONE NUMBER to view order detail</p>
              </>
              :
              <>
                <h1>Kiểm tra đơn hàng</h1>
                <p>Quý khách vui lòng nhập vào MÃ ĐƠN HÀNG</p>
                <p>và SỐ ĐIỆN THOẠI đặt xe để xem chi tiết đơn hàng.</p>
              </>
            }
            <Form
              className='order-info-form'
              form={form}
              onFinish={(value) => handleSearchOrder(value)}
            >
              <h4 className='label'>
                {language === '1' ? 'Order Code' : 'Mã đơn hàng'}
              </h4>
              <Form.Item
                // au
                name='orderCode'
                className='form-input'
                initialValue={props.orderCode}
                // type=''
                rules={[
                  { required: true, message: "Cần nhập Mã đơn hàng!" },
                  {
                    pattern: new RegExp(/^[0-9]*$/),
                    message: "Mã đơn hàng chỉ chứa số"
                  },
                  // {len: 11, message: "Mã đơn hàng cần đủ 11 chữ số"}
                ]}
              >
                <Input
                  className='order-code-input'
                  type="number"
                  pattern="[0-9]*"
                />
              </Form.Item>
              <h4 className='label'>
                {language === '1' ? 'Phone number' : 'Số điện thoại'}
              </h4>
              <Form.Item
                name='phoneNumber'
                className='form-input'
                rules={[
                  { required: true, message: "Cần nhập Số điện thoại!" },
                  {
                    pattern: new RegExp(/^[0-9]*$/),
                    message: "Số điện thoại chỉ chứa số"
                  },
                ]}
              >
                <Input
                  className='order-code-input'
                  type="number"
                  pattern="[0-9]*"
                />
              </Form.Item>
              <Form.Item className='form-input'>
                <Button
                  className='form-submit-button heading-4'
                  htmlType='submit'
                >
                  {language === '1' ? 'Search' : 'Tìm kiếm'}
                </Button>
              </Form.Item>
            </Form>
          </div>
          {/* <div className='mobile-app-link'>
            <a
              href='https://play.google.com/store/apps/details?id=vn.pippip'
              target='_blank'
              rel="noreferrer"
            >
              <img src='/Image/CheckOrder/AndroidLink.png' alt='' />
            </a>
            <a
            // todo: update link appstore
              // href='https://apps.apple.com/vn/app/pippip/id1660783160'
              href='https://apps.apple.com/vn/app/pippip/id6496683066'
              target='_blank'
              rel="noreferrer"
            >
              <img src='/Image/CheckOrder/IosLink.png' alt='' />
            </a>
          </div> */}
        </div>
        <img id='background-image' src='/Image/CheckOrder/car-checkdonhang.png' alt='' />
      </div>
      <div id='error-layer' className="error-layer hide">
        <div className="error-box">
          <div className='error-icon'></div>
          <div className="message-title">Xin lỗi!</div>
          <div className='message-content'>{errorMessage}</div>
          <button onClick={ExitErrorMessage} className='heading-4'>OK</button>
        </div>
      </div>
    </div>
  );
}
