import { useEffect, useState } from 'react';

import '../Home.css';
import {
  Text3
} from '../HomeStyle';
import { Media_source } from '../HomeContent';

export default function Component2PC() {
  const [ curSlide, setCurSlide ] = useState(0);
  const language = localStorage.getItem('language');

  const HandleSetSlide = (num) => {
    const vh = window.innerHeight;
    if (num < 0) return 0;
    else return num/vh;
  }

  const HandleScroll = () => {
    const el = document.scrollingElement || document.documentElement;
    const offsetTop = document.getElementById('second-component-pc').offsetTop;

    const slide = HandleSetSlide(el.scrollTop - offsetTop);
    if (slide !== curSlide) {
      setCurSlide(slide);
      // console.log(curSlide);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', HandleScroll)

    return () => window.removeEventListener('scroll', HandleScroll);
  });

  const HandleOpacity = () => {
    const opa = 2 * Math.abs(0.5 - curSlide + Math.floor(curSlide));

    if (opa < 0.6)
      return opa;
    else return 1;
  }

  const HandleSelectSlide = (num) => {
    const offsetTop = document.getElementById('second-component-pc').offsetTop;
    window.scrollTo(0, offsetTop + num * window.innerHeight);
  }

  return(
    // service content for pc screen:
    //    - left side is text that describe content of service
    //    - right side is image of service
    <div className="second-component-pc" id='second-component-pc'>
      <div className='left-side-content'>
        {/* order number of service */}
        <div className='slide-order'>
          <div
            className={Math.floor(curSlide + 0.3) === 0 ?
              'dot-position selected' : 'dot-position'
            }
            onClick={() => HandleSelectSlide(0)}
          ></div>
          <div
            className={Math.floor(curSlide + 0.3) === 1 ?
              'dot-position selected' : 'dot-position'
            }
            onClick={() => HandleSelectSlide(1)}
          ></div>
          <div
            className={Math.floor(curSlide + 0.3) === 2 ?
              'dot-position selected' : 'dot-position'
            }
            onClick={() => HandleSelectSlide(2)}
          />
          <div
            className={Math.floor(curSlide + 0.3) === 3 ?
              'dot-position selected' : 'dot-position'
            }
            onClick={() => HandleSelectSlide(3)}
          />
          <div
            className={Math.floor(curSlide + 0.3) === 4 ?
              'dot-position selected' : 'dot-position'
            }
            onClick={() => HandleSelectSlide(4)}
          />
          <div
            className={Math.floor(curSlide + 0.3) === 5 ?
              'dot-position selected' : 'dot-position'
            }
            onClick={() => HandleSelectSlide(5)}
          />
        </div>
        {language === '1' ?
          // render content for english language
          <div
            className="text-content"
            style={{ opacity: `${HandleOpacity()}` }}
          >
            <div className="text-box">
              <Text3>
                Explore What's New
                <p>
                  Our continuously evolving features empower you to experience travel completely.
                </p>
              </Text3>
            </div>
            <div className="text-box">
              <Text3>Go to the Airport</Text3>
            </div>
            <div className="text-box">
              <Text3>Pickup from the Airport</Text3>
            </div>
            <div className="text-box">
              <Text3>Go to the Resort</Text3>
            </div>
            <div className="text-box">
              <Text3>Rent for hour</Text3>
            </div>
            <div className="text-box">
              <Text3>Call Taxi</Text3>
            </div>
          </div>
          :
          // Render content for Vietnamese language
          <div
            className="text-content"
            style={{ opacity: `${HandleOpacity()}` }}
          >
            <div className="text-box">
              <Text3>
                Khám phá dịch vụ
                <p>
                Chúng tôi liên tục bổ sung và cải thiện chất lượng dịch vụ để khách hàng có trải nghiệm sử dụng mượt mà.
                </p>
              </Text3>
            </div>
            <div className="text-box">
              <Text3>Đón khách lên sân bay</Text3>
            </div>
            <div className="text-box">
              <Text3>Đón khách tại sân bay</Text3>
            </div>
            <div className="text-box">
              <Text3>Đi nghỉ dưỡng</Text3>
            </div>
            <div className="text-box">
              <Text3>Thuê xe theo giờ</Text3>
            </div>
            <div className="text-box">
              <Text3>Gọi taxi</Text3>
            </div>
          </div>
        }
      </div>
      <div className="img-content">
        <div className="image-box">
          {Media_source.map((e, index) => 
            <img
              key={index}
              className={Math.floor(curSlide + 0.3) === Number(index) ?
                'media media-selected' : 'media'
              }
              src={e.src}
              alt=''
            />
          )}
        </div>
      </div>
    </div>
  );
}