import { useEffect } from "react";

export default function DownloadApp() {

  useEffect(() => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    console.log(userAgent);

    if (/android/i.test(userAgent)) {
      window.location.href = 'https://play.google.com/store/apps/details?id=vn.pippip';
    }
    else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) { //todo: update link appstore
      // window.location.href = 'https://apps.apple.com/vn/app/pippip/id1660783160';
      window.location.href = 'https://apps.apple.com/vn/app/pippip/id6496683066';
    }
    else {
      window.location.href = 'http://info.pippip.vn/#mobile-link';
    }
  });
}